import { graphql } from 'gatsby';
import * as _ from 'lodash';
import React from 'react';
import { Helmet } from 'react-helmet';

import { Footer } from '../components/Footer';
import SiteNav from '../components/header/SiteNav';
import { Wrapper } from '../components/Wrapper';
import SmartImage from '../components/SmartImage';
import IndexLayout from '../layouts';
import {
  inner,
  innerNarrow,
  outer,
  outerFull,
  Section,
  SectionContent,
  SectionContentColumn,
  SectionContentContent,
  SectionIntro,
  SiteDescription,
  SiteHeader,
  SiteHeaderContent,
  SiteHeaderHero,
  SiteHeaderStyles,
} from '../styles/shared';

import config from '../website-config';
import { Facebook } from '../components/icons/facebook';
import { Instagram } from '../components/icons/instagram';

interface PartnerTemplateProps {
  location: Location;
  data: {
    markdownRemark: {
      frontmatter: {
        title: string;
      };
    };
  };
}

const PartnerTemplate = ({ data, _pageContext, location }: PartnerTemplateProps) => {
  const partner = data.markdownRemark;
  const frontmatter = partner.frontmatter;

  return (
    <IndexLayout className="post-template">
      <Helmet>
        <html lang={config.lang} />
        <title>{config.title} Partner | {frontmatter.title}</title>

        <meta name="description" content={frontmatter.homepage_excerpt} />
        <meta property="og:site_name" content={config.title} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={frontmatter.title} />
        <meta property="og:description" content={frontmatter.homepage_excerpt} />
        <meta property="og:url" content={config.siteUrl + location.pathname} />
        <meta property="article:published_time" content={frontmatter.date} />
        {config.facebook && <meta property="article:publisher" content={config.facebook} />}
        {config.facebook && <meta property="article:author" content={config.facebook} />}

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={frontmatter.title} />
        <meta name="twitter:description" content={frontmatter.homepage_excerpt} />
        <meta name="twitter:url" content={config.siteUrl + location.pathname} />
        {config.twitter && (
          <meta
            name="twitter:site"
            content={`@${config.twitter.split('https://twitter.com/')[1]}`}
          />
        )}
        {config.twitter && (
          <meta
            name="twitter:creator"
            content={`@${config.twitter.split('https://twitter.com/')[1]}`}
          />
        )}
      </Helmet>
      <Wrapper>
        <div
            css={[outer, SiteHeader, SiteHeaderStyles]}
            className="site-header-background"
          >
          <div css={inner}>
            <SiteNav isHome />
            <SiteHeaderContent className="site-header-content">
              <SiteHeaderHero>
                {
                  frontmatter.logo.extension.length > 0 ? <SmartImage imageInfo={frontmatter.logo} /> : frontmatter.title
                }
              </SiteHeaderHero>

              <SiteDescription>{config.description}</SiteDescription>
            </SiteHeaderContent>
          </div>
        </div>

        <div css={outerFull}>
          <div css={innerNarrow}>
            <SectionIntro>
              <h2><em>{frontmatter.tagline}</em></h2>
            </SectionIntro>
          </div>
        </div>

        <div css={outer}>
          {frontmatter.content_with_image.map((content_with_image, index) => {
            return (
              <Section css={inner} key={index}>
                <SectionContent>
                  <SectionContentColumn>
                    <SectionContentContent>
                      <SmartImage imageInfo={content_with_image.image} style={{minHeight: '300px'}} />
                    </SectionContentContent>
                  </SectionContentColumn>

                  <SectionContentColumn>
                    <SectionContentContent>
                      <div dangerouslySetInnerHTML={{ __html: content_with_image.content }} />
                      { content_with_image.button.length > 0 && (
                        <a
                          href={content_with_image.button[0].url}
                          className={content_with_image.button[0].css_classes}
                          rel='noopener'
                        >
                          {content_with_image.button[0].content}
                        </a>
                      )}
                    </SectionContentContent>
                  </SectionContentColumn>
                </SectionContent>
              </Section>
            )
          })}
        </div>

        <div css={outer}>
          <Section css={inner}>
            <SectionIntro>
              <h3>About {frontmatter.title}</h3>
            </SectionIntro>
            <SectionContent>
              <SectionContentColumn>
                <SectionContentContent>
                  <div dangerouslySetInnerHTML={{ __html: frontmatter.bio }} />

                  <ul style={{listStyleType: 'none', marginTop: '3em', paddingLeft: 0, textAlign: 'center'}}>
                    <li style={{marginBottom: '1em'}}>
                      <a href={frontmatter.instagram} rel='noopener noreferrer' target='_blank'>
                        <Instagram style={{width: '30px', marginRight: '10px' }}/> {frontmatter.title} on Instagram
                      </a>
                    </li>
                    <li>
                      <a href={frontmatter.facebook} rel='noopener noreferrer' target='_blank'>
                        <Facebook style={{width: '30px', marginRight: '10px' }} /> {frontmatter.title} on Facebook
                      </a>
                    </li>
                  </ul>
                </SectionContentContent>
              </SectionContentColumn>
            </SectionContent>
          </Section>
        </div>

        <Footer />
      </Wrapper>
    </IndexLayout>
  );
};

export const query = graphql`
  query($slug: String) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        bio: html(from: "bio")
        facebook
        instagram
        quote
        tagline
        title
        website
        logo {
          extension
          publicURL
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        content_with_image {
          content: html(from: "content")
          image_alt_text
          image_position
          button {
            content
            css_classes
            url
          }
          image {
            extension
            publicURL
            childImageSharp {
              fluid(maxWidth: 300) {
                base64
                originalImg
                originalName
                srcSetWebp
                srcWebp
              }
            }
          }
        }
      }
    }
  }
`;

export default PartnerTemplate;
